import React, { createContext, useState, Dispatch, SetStateAction } from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'shards-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPen, faMap, faImages, faDownload, faFilePdf } from '@fortawesome/free-solid-svg-icons';
import { CSVLink } from "react-csv";
import { Report, defaultReportContext, ReportContext, ReportDispatchContext } from '../context/ReportContext';
import { PDFDownloadLink, Document, Page, PDFViewer } from '@react-pdf/renderer';
import config from '../config'
import ReportPDF from '../components/media/ReportPDF';
import './Myeloaid02.css';



const MyeloaidPageLayout02 = ({ children }: any) => {
    const [report, setReport] = useState(defaultReportContext);
    
    return (
    <>
        <div style={{
            position: "relative",
            zIndex: 99,
            justifyContent: 'space-between',
            alignItems: 'center', display: 'flex', height: "3rem", backgroundColor: "#000", width: "100%"}}>
            <img style={{height: "3rem"}}  src={`${config.publicRoot}logo.webp`}></img>
            <div className="center-helper-top" style={{
                alignItems: 'center', height: "3rem", justifyContent: 'center', columnGap: '4rem', fontSize: '1.5rem'}}>
                <FontAwesomeIcon icon={faMap} className="minimap-icon" style={{color: "#ffffff", cursor: "pointer"}} 
                    onClick={() => document.querySelector("#wsi-pagemap-container")?.classList.toggle("pagemap-toggle")} />
                <FontAwesomeIcon icon={faImages} style={{color: "#ffffff", cursor: "pointer"}} 
                    onClick={() => document.querySelector("#wsi-carousel-thumbs")?.classList.toggle("slide-toggle-top")} />
                <CSVLink
                    data={report.data}
                    onClick={() => {
                        console.log("You click the link"); // 👍🏻 Your click handling logic
                    }}
                    className="subscript csv-link"
                >
                    <FontAwesomeIcon icon={faDownload} style={{color: "#ffffff", cursor: "pointer"}} 
                        onClick={() => {}} />
                </CSVLink>
                <PDFDownloadLink document={<ReportPDF report={report} />} fileName="report.pdf" className="subscript pdf-link">
                    {({ blob, url, loading, error }) =>
                        <FontAwesomeIcon icon={faDownload} style={{color: "#ffffff", cursor: "pointer"}} 
                        onClick={() => {}} />
                    }
                </PDFDownloadLink>
                <FontAwesomeIcon icon={faFilePdf} className="minimap-icon" style={{color: "#ffffff", cursor: "pointer"}} 
                    onClick={() => document.querySelector(".pdf-viewer")?.classList.toggle("pagemap-toggle")} />
                
            </div>
        </div>
        <Container fluid>
                <PDFViewer className="pdf-viewer animated-toggle" style={{ position: "absolute", left: "-1000px", zIndex: 99, top: "10vh"}} height={500} width={1000}>
                    <ReportPDF report={report} />
                </PDFViewer> 
            <Row>
                <Col
                    className="p-0 w-100"
                    lg={{ size: 12, offset:0 }}
                    sm="12"
                    tag="main"
                >
                    <ReportContext.Provider value={report}>
                        <ReportDispatchContext.Provider value={setReport}>
                            {children}
                        </ReportDispatchContext.Provider>
                    </ReportContext.Provider>
                </Col>
            </Row>
        </Container>
    </>
)};

MyeloaidPageLayout02.propTypes = {
    children: PropTypes.node,
};

MyeloaidPageLayout02.defaultProps = {};

export default MyeloaidPageLayout02;
