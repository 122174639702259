import React from 'react';
import { Redirect } from 'react-router-dom';

// Layout Types
import { DefaultLayout, LandingPageLayout } from './layouts';

// Route Views
import LandingPage from './views/LandingPage';
import WsiSegmentation from './views/WsiSegmentation';
import RoiAnnotation from './views/RoiAnnotation';
import PreselectedCellAnnotation from './views/PreselectedCellAnnotation';
import CellAnnotation from './views/CellAnnotation';
import CellAnnotationComparison from './views/CellAnnotationComparison';
import BlogOverview from './views/BlogOverview';
import UserProfileLite from './views/UserProfileLite';
import AddNewPost from './views/AddNewPost';
import Errors from './views/Errors';
import ComponentsOverview from './views/ComponentsOverview';
import Tables from './views/Tables';
import BlogPosts from './views/BlogPosts';
import SegmentationAnnotation from './views/SegmentationAnnotation';
import SegmentationAnnotationErythrocytes from './views/SegmentationAnnotationErythrocytes';
import VideoDemo1 from './views/VideoDemo1';
import VideoDemo2 from './views/VideoDemo2';
import Logout from './views/Logout';
import WsiUpload from './views/WsiUpload';
import ViaExport from './views/ViaExport';
import Utility from './views/Utility';
import MyeloaidPageLayout from './layouts/Myeloaid';
import MyeloaidPageLayout02 from './layouts/Myeloaid02';
import NoSidebarLayout from 'layouts/NoSidebar';

const DefaultRedirect = () => <Redirect to="/landingpage" />;

const routes = [
    {
        path: '/',
        exact: true,
        layout: DefaultLayout,
        component: DefaultRedirect,
    },
    {
        path: '/landingpage',
        layout: LandingPageLayout,
        component: LandingPage,
    },
    {
        path: '/preselectedcell-annotation',
        layout: NoSidebarLayout,
        component: PreselectedCellAnnotation,
    },
    {
        path: '/cell-annotation',
        layout: DefaultLayout,
        component: CellAnnotation,
    },
    {
        path: '/cell-annotation-comparison',
        layout: DefaultLayout,
        component: CellAnnotationComparison,
    },
    {
        path: '/roi-annotation',
        layout: DefaultLayout,
        component: RoiAnnotation,
    },
    // {
    //     path: '/wsi-segmentation',
    //     layout: DefaultLayout,
    //     component: WsiSegmentation,
    // },
    {
        path: '/microscope-cell-segmentation',
        layout: DefaultLayout,
        component: SegmentationAnnotation,
    },
    {
        path: '/erythrocyte-segmentation',
        layout: DefaultLayout,
        component: SegmentationAnnotationErythrocytes,
    },
    {
        path: '/via-export',
        layout: DefaultLayout,
        component: ViaExport,
    },
    {
        path: '/wsi-upload',
        layout: DefaultLayout,
        component: WsiUpload,
    },
    {
        path: '/utility',
        layout: DefaultLayout,
        component: Utility,
    },
    {
        path: '/video-demo-1',
        layout: DefaultLayout,
        component: VideoDemo1,
    },
    {
        path: '/video-demo-2',
        layout: DefaultLayout,
        component: VideoDemo2,
    },
    {
        path: '/blog-overview',
        layout: DefaultLayout,
        component: BlogOverview,
    },
    {
        path: '/logout',
        layout: DefaultLayout,
        component: Logout,
    },
    {
        path: '/user-profile-lite',
        layout: DefaultLayout,
        component: UserProfileLite,
    },
    {
        path: '/add-new-post',
        layout: DefaultLayout,
        component: AddNewPost,
    },
    {
        path: '/errors',
        layout: DefaultLayout,
        component: Errors,
    },
    {
        path: '/components-overview',
        layout: DefaultLayout,
        component: ComponentsOverview,
    },
    {
        path: '/tables',
        layout: DefaultLayout,
        component: Tables,
    },
    {
        path: '/blog-posts',
        layout: DefaultLayout,
        component: BlogPosts,
    },
];

export default routes;
