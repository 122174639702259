import React, { ReactChild, useEffect, useState } from 'react';
import Select from 'react-select';
import styled from 'styled-components';
import { Dataset, Wsi } from '../../models/BackendModels';
import config from './../../config';
import { checkSuccess, auth_fetch } from './../../utils/ajax';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

interface WsiSelectProps {
    className?: string;
    dataset?: Dataset;
    availableWsi?: Wsi[];
    selectedWsi?: Wsi;
    onChange: (selectedWsi: Wsi) => void;
    isDisabled?: boolean;
    useNameAsLabel?: boolean;
}

class WsiOption {
    public label = 'Wsi';
    public value = '0';
    public model?: Wsi;

    constructor(wsi?: Wsi, useNameAsLabel?: boolean) {
        if (wsi) {
            this.label = useNameAsLabel ? wsi.imgName.replace(/\.[^/.]+$/, '') : String(wsi.id);
            this.value = String(wsi.id);
            this.model = wsi;
        }
    }
}
// Required to prevent typescript bug?
const StyledSelect = styled(Select)``;

// interface WsiOption {
//     label: string;
//     value: number;
//     selected: boolean;
//     pixelDiameterInMicrometer: number;
//     datasetId: number;
// }

const defaultWsiOption = new WsiOption();

export const retrieveWsi = (datasetId: number) => auth_fetch(`${config.backend}/api/dataset_wsi/${datasetId}`);

type WsiCarouselProps = WsiSelectProps & {
    renderItem?: (item: React.ReactNode, options?: { isSelected: boolean }) => React.ReactNode, 
    renderThumbs?: (children: ReactChild[]) => ReactChild[]
};


export const WsiCarousel: React.FC<WsiCarouselProps> = ({
    className,
    dataset,
    availableWsi,
    selectedWsi,
    onChange,
    isDisabled,
    useNameAsLabel,
    renderItem,
    renderThumbs,
}: WsiCarouselProps) => {
    // const [selectedOption, setSelectedOption] = useState(defaultDatasetOption);
    // const [wsiOptions, setWsiOptions] = useState<WsiOption[]>([]);
    const [wsiOptions, setWsiOptions] = useState<WsiOption[]>([]);

    useEffect(() => {
        if (typeof dataset !== 'undefined' && typeof availableWsi === 'undefined') {
            retrieveWsi(dataset.id)
                .then(checkSuccess)
                .then((data: Wsi[]) => setWsiOptions(data.map((wsi) => new WsiOption(wsi, useNameAsLabel))));
            // (data) =>
            // setAvailableWsi(
            // data.map((wsi: Wsi) => {
            //     return {
            //         label: wsi.imgName, value: wsi.id, selected: false,
            //         pixelDiameterInMicrometer: wsi.pixelDiameterInMicrometer, datasetId: wsi.datasetId
            //     };
            // })));
        } else if (typeof availableWsi !== 'undefined') {
            setWsiOptions(availableWsi.map((wsi) => new WsiOption(wsi, useNameAsLabel)));
        }
    }, [dataset, availableWsi, useNameAsLabel]);
    return (
        <Carousel 
            showArrows={false}
            showStatus={false}
            renderThumbs={renderThumbs}
            selectedItem={wsiOptions.findIndex((o) => o.value === String(selectedWsi?.id))}>
        </Carousel>
    )
};

const WsiSelect: React.FC<WsiSelectProps> = ({
    className,
    dataset,
    availableWsi,
    selectedWsi,
    onChange,
    isDisabled,
    useNameAsLabel,
}: WsiSelectProps) => {
    // const [selectedOption, setSelectedOption] = useState(defaultDatasetOption);
    // const [wsiOptions, setWsiOptions] = useState<WsiOption[]>([]);
    const [wsiOptions, setWsiOptions] = useState<WsiOption[]>([]);

    useEffect(() => {
        if (typeof dataset !== 'undefined' && typeof availableWsi === 'undefined') {
            retrieveWsi(dataset.id)
                .then(checkSuccess)
                .then((data: Wsi[]) => setWsiOptions(data.map((wsi) => new WsiOption(wsi, useNameAsLabel))));
            // (data) =>
            // setAvailableWsi(
            // data.map((wsi: Wsi) => {
            //     return {
            //         label: wsi.imgName, value: wsi.id, selected: false,
            //         pixelDiameterInMicrometer: wsi.pixelDiameterInMicrometer, datasetId: wsi.datasetId
            //     };
            // })));
        } else if (typeof availableWsi !== 'undefined') {
            setWsiOptions(availableWsi.map((wsi) => new WsiOption(wsi, useNameAsLabel)));
        }
    }, [dataset, availableWsi, useNameAsLabel]);
    const selectedOption = wsiOptions.find((o) => o.value === String(selectedWsi?.id));
    return (
        <StyledSelect
            isDisabled={isDisabled}
            className={className}
            placeholder="Select WSI"
            isSearchable={true}
            options={wsiOptions}
            value={selectedOption ? selectedOption : defaultWsiOption}
            onChange={(o: WsiOption) => (o.model ? onChange(o.model) : null)}
        ></StyledSelect>
    );
};

// {
/* <StyledSelect
value={selectedOption ? selectedOption : defaultWsiOption}
placeholder="Wsi name"
isSearchable={true}
onChange={(o: WsiOption) => onChange({
id: o.value, imgName: o.label,
pixelDiameterInMicrometer: o.pixelDiameterInMicrometer, datasetId: o.datasetId
})}
options={wsiOptions}
/> */
// }
export default WsiSelect;
