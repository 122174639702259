import React from 'react';
import PropTypes from 'prop-types';
import { Navbar, NavbarBrand } from 'shards-react';

import { Dispatcher, Constants } from '../../../flux';

class SidebarMainNavbar extends React.Component {
    constructor(props) {
        super(props);

        this.handleToggleSidebar = this.handleToggleSidebar.bind(this);
    }

    handleToggleSidebar() {
        Dispatcher.dispatch({
            actionType: Constants.TOGGLE_SIDEBAR,
        });
    }

    render() {
        const { hideLogoText } = this.props;
        return (
            <div className="main-navbar" style={{ backgroundColor: "#001f77" }}>
                <Navbar className="align-items-stretch bg-white flex-md-nowrap border-bottom p-0" style={{ backgroundColor: "#001f77 !important" }} type="light">
                    <NavbarBrand className="w-100 mr-0" href="#" style={{ lineHeight: '25px', backgroundColor: "#001f77"  }}>
                        <div className="d-table m-auto">
                            <img
                                id="main-logo"
                                className="d-inline-block align-top mr-1"
                                style={{ height: '2.5rem' }}
                                src="https://cancilico.com/wp-content/uploads/2023/11/Main-Logo.png"
                                alt="Shards Dashboard"
                            />
                            {!hideLogoText && <span className="d-none d-md-inline ml-1"></span>}
                        </div>
                    </NavbarBrand>
                    {/* eslint-disable-next-line */}
          <a
            className="toggle-sidebar d-sm-inline d-md-none d-lg-none"
            onClick={this.handleToggleSidebar}>
                        <i className="material-icons">&#xE5C4;</i>
                    </a>
                </Navbar>
            </div>
        );
    }
}

SidebarMainNavbar.propTypes = {
    /**
     * Whether to hide the logo text, or not.
     */
    hideLogoText: PropTypes.bool,
};

SidebarMainNavbar.defaultProps = {
    hideLogoText: false,
};

export default SidebarMainNavbar;
