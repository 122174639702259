import React, { ChangeEvent, FormEvent, ReactText, useContext, useEffect, useRef, useState } from 'react';
import Loader from 'react-loader-spinner';
import { trackPromise, usePromiseTracker } from 'react-promise-tracker';
import Select from 'react-select';
import { Button, Card, CardBody, CardHeader, Col, Container, Row, FormCheckbox } from 'shards-react';
import PageTitle from '../components/common/PageTitle';
import { Dataset, Wsi, ViaAnnotation } from '../models/BackendModels';
import DatasetSelect from './../components/wsi/DatasetSelect';
import WsiSelect, { retrieveWsi } from './../components/wsi/WsiSelect';
import config from './../config';
import { UserContext } from './../context/UserContext';
import { auth_fetch, checkSuccess } from './../utils/ajax';
import styled from 'styled-components';
import styles from './Common.module.css';
import { saveViaProjectToBackend } from './../components/wsi/ViaProjects';
import { filterDistinct } from './../utils/util';
import { Option, DynamicCnfSelectGroup, Cnf, Clause } from './../components/common/FormInputs';


export const bbox2rect = (bbox: number[]) => [bbox[0], bbox[1], bbox[2] - bbox[0], bbox[3] - bbox[1]];

export const bbox2ellipse = (bbox: number[]) => {
    const x_center = (bbox[1] + bbox[3]) / 2;
    const y_center = (bbox[0] + bbox[2]) / 2;
    const x_radius = (bbox[3] - bbox[1]) / 2;
    const y_radius = (bbox[2] - bbox[0]) / 2;
    return [y_center, x_center, y_radius, x_radius];
};

export const bbox2maxcircle = (bbox: number[]) => {
    const x_center = (bbox[1] + bbox[3]) / 2;
    const y_center = (bbox[0] + bbox[2]) / 2;
    const radius = Math.max(bbox[3] - bbox[1], bbox[2] - bbox[0]) / 2;
    return [y_center, x_center, radius];
};

export const bbox2mincircle = (bbox: number[]) => {
    const x_center = (bbox[1] + bbox[3]) / 2;
    const y_center = (bbox[0] + bbox[2]) / 2;
    const radius = Math.min(bbox[3] - bbox[1], bbox[2] - bbox[0]) / 2;
    return [y_center, x_center, radius];
};

export type CellSlice = {
    idx: number;
    bbox: number[];
    label?: number;
    imageName?: string;
};



const Form = styled.form`
    display: grid;
    grid:
        [start] 'col' 'col' 'col' 1fr [end]
        / 1fr 1fr 1fr;
    justify-items: end;
`;

const Input = styled.input`
    margin-left: 15px;
`;

interface LoadingIndicatorProps {
    active: boolean;
}

export const LoadingIndicator: React.FC<LoadingIndicatorProps> = ({ active }: LoadingIndicatorProps) => {
    const { promiseInProgress } = usePromiseTracker();

    return active && promiseInProgress ? <Loader type="ThreeDots" color="#2BAD60" height={100} width={100} /> : null;
};

// const a = [{ id: 1 }, { id: 1 }, { id: 3 }];
// const b = [{ idObj: { id: 1 } }, { idObj: { id: 1 } }, { idObj: { id: 3 } }];
// const c = [1, 1, 3]

// let t = filterDistinct(a, (a, b) => a.id === b.id);
// t = filterDistinct(b, (a, b) => a.idObj.id === b.idObj.id);
// t = filterDistinct(c);

const backendWsiImageUrl = (wsi?: Wsi) => (typeof wsi !== 'undefined' ? `${wsi.webUrl}` : undefined);

interface CircleFormProps {
    circleHandler: (data: Array<Array<number>>) => void;
    wsi?: Wsi;
}

const CircleForm: React.FC<CircleFormProps> = ({ circleHandler, wsi }: CircleFormProps) => {
    const [inputs, setInputs] = useState<Record<string, ReactText>>({
        pixelDiameterInMicrometer: 0,
        kernelDim: 5,
        pixelMinCellRadius: 1.5,
        pixelMaxCellRadius: 6.6,
        pixelMinCellCenterDist: 4.5,
        dilateIterations: 1,
        houghCirclesDp: 3.2,
        denoiseH: 20,
        denoiseTemplateWindow: 7,
        denoiseSearchWindow: 21,
    });

    const [loadingIndicatorActive, setLoadingIndicatorActive] = useState<boolean>(false);

    useEffect(() => {
        if (typeof wsi !== 'undefined') {
            setInputs({
                ...inputs,
                pixelDiameterInMicrometer: wsi.pixelDiameterInMicrometer,
                pixelMinCellRadius: 1.5 / wsi.pixelDiameterInMicrometer,
                pixelMaxCellRadius: 6.6 / wsi.pixelDiameterInMicrometer,
                pixelMinCellCenterDist: 4.5 / wsi.pixelDiameterInMicrometer,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [wsi]);

    const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        setInputs({ ...inputs, [event.target.name]: Number(event.target.value) });
    };

    const submitHandler = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (typeof wsi !== 'undefined') {
            setLoadingIndicatorActive(true);
            trackPromise(
                auth_fetch(`${config.backend}/api/circles/`, {
                    method: 'POST',
                    body: JSON.stringify({
                        ...inputs,
                        wsi: typeof wsi !== 'undefined' ? wsi.id : undefined,
                    }),
                    headers: { 'Content-Type': 'application/json' },
                })
                    .then(checkSuccess)
                    .then((data) => {
                        setLoadingIndicatorActive(false);
                        circleHandler(data);
                    })
                    .catch((error) => console.log(`Could not create circles: ${error}`)),
            );
        }
    };

    return (
        <Card>
            <CardHeader className="border-bottom">
                <h6 className="m-0">
                    {' '}
                    <Button type="submit" theme="primary" onClick={submitHandler}>
                        New circles
                    </Button>
                </h6>
                <div className="block-handle" />
            </CardHeader>

            <CardBody className="border-top">
                <Form>
                    {Object.keys(inputs).map((key) => (
                        <label key={key}>
                            {key}
                            <Input
                                name={key}
                                type="number"
                                disabled={key === 'pixelDiameterInMicrometer' ? true : false}
                                value={inputs[key]}
                                onChange={handleInputChange}
                            />
                        </label>
                    ))}
                    <LoadingIndicator active={loadingIndicatorActive} />
                </Form>
            </CardBody>
        </Card>
    );
};

const StyledSelect = styled(Select)`
    width: 60%;
    display: inline-block;
`;

type ModelInferenceOption = Option;

interface ObjectDetectionInferenceProps {
    wsi?: Wsi;
    handler: (data: number[][]) => void;
    availableModelOptions: Array<ModelInferenceOption>;
}

interface ModelInferenceProps {
    wsi?: Wsi;
    shapeHandler: (shape: string, data: Array<Array<number>>) => void;
    availableModelOptions: Array<ModelInferenceOption>;
}

interface ClassificationModelInferenceProps {
    wsi: Wsi;
    cellSlices: CellSlice[];
    handler: ([props]: any) => void;
    availableModelOptions: Array<ModelInferenceOption>;
}

const defaultModelOption = {
    value: '0',
    label: 'Choose model to use for inference',
};

export const retrieveModels = () =>
    auth_fetch(`${config.backend}/api/model_inference/`, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
    });

export const retrieveClassificationModels = () =>
    auth_fetch(`${config.backend}/api/classification_inference/`, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
    });

export const ObjectDetectionInference: React.FC<ObjectDetectionInferenceProps> = ({
    wsi,
    handler,
    availableModelOptions,
}: ObjectDetectionInferenceProps) => {
    // const {user} = useContext(UserContext);
    const [loadingIndicatorActive, setLoadingIndicatorActive] = useState<boolean>(false);

    // const [availableUserProjectOptions, setAvailableUserProjectOptions] = useState([]);
    const [selectedModelInferenceOption, setSelectedModelInferenceOption] = useState(defaultModelOption);
    const submitHandler = (modelName: string) => {
        if (typeof wsi !== 'undefined') {
            setLoadingIndicatorActive(true);
            trackPromise(
                auth_fetch(`${config.backend}/api/model_inference/`, {
                    method: 'POST',
                    body: JSON.stringify({
                        modelName: modelName,
                        targetShape: 'rect', //TODO unused => remove
                        wsi: typeof wsi !== 'undefined' ? wsi.id : undefined,
                    }),
                    headers: { 'Content-Type': 'application/json' },
                })
                    .then(checkSuccess)
                    .then((data) => {
                        setLoadingIndicatorActive(false);
                        handler(data);
                    })
                    .catch((error) => console.log(`Could not create circles: ${error}`)),
            );
        }
    };

    return (
        <div
            style={{
                display: 'flex',
                flexFlow: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                width: '70%',
            }}
        >
            <StyledSelect
                value={selectedModelInferenceOption}
                onChange={(option: ModelInferenceOption) =>
                    setSelectedModelInferenceOption(option as ModelInferenceOption)
                }
                placeholder="Choose model to use for inference"
                isSearchable={false}
                options={availableModelOptions}
            />
            <Button
                theme="primary"
                disabled={selectedModelInferenceOption.value === '0'}
                onClick={(/*e: FormEvent<HTMLFormElement>*/) => submitHandler(selectedModelInferenceOption.value)}
            >
                Start analysis
            </Button>
            <LoadingIndicator active={loadingIndicatorActive} />
        </div>
    );
};

export const ModelInference: React.FC<ModelInferenceProps> = ({
    wsi,
    shapeHandler,
    availableModelOptions,
}: ModelInferenceProps) => {
    // const {user} = useContext(UserContext);
    const [loadingIndicatorActive, setLoadingIndicatorActive] = useState<boolean>(false);

    // const [availableUserProjectOptions, setAvailableUserProjectOptions] = useState([]);
    const [selectedModelInferenceOption, setSelectedModelInferenceOption] = useState(defaultModelOption);
    const availableTargetShapeOptions = [
        { value: 'ellipse', label: 'Ellipse' },
        { value: 'rect', label: 'Rectangle' },
        // { value: 'max_circle', label: 'Max circle' },
        // { value: 'min_circle', label: 'Min circle' },
    ];
    const [selectedTargetShape, setSelectedTargetShape] = useState(availableTargetShapeOptions[0]);
    const submitHandler = (modelName: string, targetShape: string) => {
        if (typeof wsi !== 'undefined') {
            setLoadingIndicatorActive(true);
            trackPromise(
                auth_fetch(`${config.backend}/api/model_inference/`, {
                    method: 'POST',
                    body: JSON.stringify({
                        modelName: modelName,
                        targetShape: targetShape,
                        wsi: typeof wsi !== 'undefined' ? wsi.id : undefined,
                    }),
                    headers: { 'Content-Type': 'application/json' },
                })
                    .then(checkSuccess)
                    .then((data) => {
                        setLoadingIndicatorActive(false);
                        shapeHandler(targetShape, data);
                    })
                    .catch((error) => console.log(`Could not create circles: ${error}`)),
            );
        }
    };

    return (
        <div
            style={{
                display: 'flex',
                flexFlow: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                width: '70%',
            }}
        >
            <StyledSelect
                value={selectedModelInferenceOption}
                onChange={(option: ModelInferenceOption) =>
                    setSelectedModelInferenceOption(option as ModelInferenceOption)
                }
                placeholder="Choose model to use for inference"
                isSearchable={false}
                options={availableModelOptions}
            />
            <StyledSelect
                value={selectedTargetShape}
                onChange={(option: any) => setSelectedTargetShape(option)}
                placeholder="Choose target shape"
                isSearchable={false}
                options={availableTargetShapeOptions}
            />
            <Button
                theme="primary"
                disabled={selectedModelInferenceOption.value === '0'}
                onClick={
                    (/*e: FormEvent<HTMLFormElement>*/) =>
                        submitHandler(selectedModelInferenceOption.value, selectedTargetShape.value)
                }
            >
                Start analysis
            </Button>
            <LoadingIndicator active={loadingIndicatorActive} />
        </div>
    );
};

// export const classificationInference = (modelName: string, wsi: Wsi): Promise<any[]> =>
//     auth_fetch(`${config.backend}/api/classification_inference/`, {
//         method: 'POST',
//         body: JSON.stringify({
//             modelName: modelName,
//             wsi: typeof wsi !== 'undefined' ? wsi.id : undefined,
//         }),
//         headers: { 'Content-Type': 'application/json' },
//     }).then(checkSuccess)

export const ClassificationModelInference: React.FC<ClassificationModelInferenceProps> = ({
    wsi,
    cellSlices,
    handler,
    availableModelOptions,
}: ClassificationModelInferenceProps) => {
    // const {user} = useContext(UserContext);
    const [loadingIndicatorActive, setLoadingIndicatorActive] = useState<boolean>(false);

    // const [availableUserProjectOptions, setAvailableUserProjectOptions] = useState([]);
    const [selectedModelInferenceOption, setSelectedModelInferenceOption] = useState(defaultModelOption);
    const submitHandler = (modelName: string) => {
        if (typeof wsi !== 'undefined') {
            setLoadingIndicatorActive(true);
            trackPromise(
                auth_fetch(`${config.backend}/api/classification_inference/`, {
                    method: 'POST',
                    body: JSON.stringify({
                        modelName: modelName,
                        cellSlices: cellSlices.map((slice) => slice.bbox),
                        wsi: typeof wsi !== 'undefined' ? wsi.id : undefined,
                    }),
                    headers: { 'Content-Type': 'application/json' },
                })
                    .then(checkSuccess)
                    .then((data) => {
                        setLoadingIndicatorActive(false);
                        handler(data);
                    })
                    .catch((error) => console.log(`Could not create circles: ${error}`)),
            );
        }
    };

    return (
        <div
            style={{
                display: 'flex',
                flexFlow: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                width: '70%',
            }}
        >
            <StyledSelect
                value={selectedModelInferenceOption}
                onChange={(option: ModelInferenceOption) =>
                    setSelectedModelInferenceOption(option as ModelInferenceOption)
                }
                placeholder="Choose model to use for inference"
                isSearchable={false}
                options={availableModelOptions}
            />
            <Button
                theme="primary"
                disabled={selectedModelInferenceOption.value === '0'}
                onClick={(/*e: FormEvent<HTMLFormElement>*/) => submitHandler(selectedModelInferenceOption.value)}
            >
                Start analysis
            </Button>
            <LoadingIndicator active={loadingIndicatorActive} />
        </div>
    );
};

interface UserProjectOption {
    label: string;
    value: number;
}

interface ViaProjectImportProps {
    wsi?: Wsi;
    importHandler: (userIdToImportFrom: number) => void;
    availableUserProjectOptions: Array<UserProjectOption>;
}

const defaultUserProjectOption = {
    value: 0,
    label: 'Choose user to import project from',
};

const retrieveViaProjects = (wsiId?: number) =>
    auth_fetch(`${config.backend}/api/via/` + (wsiId ? `${wsiId}/` : ``), {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
    });

const ViaProjectImport: React.FC<ViaProjectImportProps> = ({
    /*wsi,*/
    importHandler,
    availableUserProjectOptions,
}: ViaProjectImportProps) => {
    const { user } = useContext(UserContext);

    // const [availableUserProjectOptions, setAvailableUserProjectOptions] = useState([]);
    const [selectedUserProjectOption, setSelectedUserProjectOption] = useState(defaultUserProjectOption);

    useEffect(() => {
        const selectCurrentUserOption = availableUserProjectOptions.find(
            (option: UserProjectOption) => option.value === user.id,
        );
        setSelectedUserProjectOption(selectCurrentUserOption ? selectCurrentUserOption : defaultUserProjectOption);
    }, [user, availableUserProjectOptions]);

    return (
        <div style={{ width: '70%' }}>
            <StyledSelect
                value={selectedUserProjectOption}
                onChange={(option: UserProjectOption) => setSelectedUserProjectOption(option as UserProjectOption)}
                placeholder="Choose user to import project from"
                isSearchable={false}
                options={availableUserProjectOptions}
            />
            <Button
                theme="primary"
                onClick={(/*e: FormEvent<HTMLFormElement>*/) => importHandler(selectedUserProjectOption.value)}
            >
                Import project from user
            </Button>
        </div>
    );
};

const ViaFrame = styled.iframe`
    width: 100%;
    height: 2000px;
`;

enum WsiAnnotationFilter {
    ONLY_ANNOTATED,
    ONLY_NON_ANNOTATED,
    ALL,
}

const filterWsiAnnotatedByDefault = new Cnf<Option>([
    [
        {
            value: '0',
            label: 'Nobody',
        },
    ],
]);

// const ImportRow = styled(Row)`
//     display: flex;
//     justify-content: space-between;
// `;
const replaceBackend = (viaProject: any) => {
    const oldBackend = '172.26.62.216:8000';
    const newBackend = 'backend.annotation-platform.site';
    viaProject['file']['1']['src'] = viaProject['file']['1']['src'].replace(oldBackend, newBackend);
    return viaProject;
};

const SegmentationAnnotationErythrocytes: React.FC = () => {
    // const [username, setUsername] = useState('');
    // const [expertise, setExpertise] = useState(2);
    const { user } = useContext(UserContext);
    const [currentDataset, setCurrentDataset] = useState<Dataset | undefined>();
    const [currentWsi, setCurrentWsi] = useState<Wsi | undefined>();
    // const [wsiInCurrentDataset, setWsiInCurrentDataset] = useState<Wsi[]>([]);
    const [availableWsi, setAvailableWsi] = useState<Wsi[]>([]);
    const [allViaAnnotations, setAllViaAnnotations] = useState<ViaAnnotation[]>([]);
    // const [filterAnnotatedWsi, setFilterAnnotatedWsi] = useState<WsiAnnotationFilter | null>(null);
    const [filterWsiAnnotatedBy, setFilterWsiAnnotatedBy] = useState<Cnf<Option>>(filterWsiAnnotatedByDefault);

    // const [isLoading, setIsLoading] = useState(false);
    // const [attached, setAttached] = useState(false);
    const viaFrameRef = useRef<HTMLIFrameElement>(null);
    const [viaFrameHasLoaded, setViaFrameHasLoaded] = useState(false);
    const [availableModelInferenceOptions, setAvailableModelInferenceOptions] = useState([]);
    const [availableUserProjectOptions, setAvailableUserProjectOptions] = useState([]);
    const [usersWithAnnotationsOptions, setUsersWithAnnotationsOptions] = useState<Set<Option>>(new Set());
    // const [selectedViaProject, setSelectedViaProject] = useState('');

    const updateViaFrame = (userId: number, wsi?: Wsi) => {
        if (typeof wsi !== 'undefined') {
            const payload = {
                action: 'load',
                imgName: backendWsiImageUrl(wsi),
                wsiId: wsi.id,
            };
            // sendToViaFrame(payload);
            retrieveViaProject(userId, wsi)
                .then(checkSuccess)
                .then((data) => JSON.parse(data.viaProject))
                .then(replaceBackend)
                .then((viaProject) => sendToViaFrame({ ...payload, viaProject }))
                .catch((/*error*/) => {
                    sendToViaFrame(payload);
                    console.log('No existing annotation');
                });
        }
    };

    const updateModelInferenceOptions = () => {
        retrieveModels()
            .then(checkSuccess)
            .then((models) => {
                const available = models.map((element: string /*idx: number*/) => {
                    return { value: element, label: element };
                });
                setAvailableModelInferenceOptions(available);
            })
            .catch((/*error*/) => console.log('No existing models, yet'));
    };

    const updateViaProjectOptions = (wsiId: number) => {
        retrieveViaProjects(wsiId)
            .then(checkSuccess)
            .then((projects) => {
                const available = projects.map((element: Record<string, unknown> /*idx: number*/) => {
                    return { value: element.annotator, label: element.annotatorName };
                });
                setAvailableUserProjectOptions(available);
            })
            .catch((/*error*/) => console.log('No existing via projects, yet'));
    };

    useEffect(() => {
        if (viaFrameHasLoaded) {
            updateViaFrame(user.id, currentWsi);
        }

        const handleViaFrameMessages = (event: MessageEvent) => {
            if (/^react-devtools/gi.test(event.data.source)) {
                return;
            }
            if (!event.data.error) {
                if (event.data.action === 'loaded') {
                    setViaFrameHasLoaded(true);
                }
                if (
                    event.data.action === 'saveViaDataStore' &&
                    typeof currentWsi !== 'undefined' &&
                    typeof event.data.viaDataStore !== 'undefined'
                ) {
                    saveViaProjectToBackend(user.id, currentWsi.id, event.data.viaDataStore, true).then(() =>
                        updateViaProjectOptions(currentWsi.id),
                    );
                }
            }
        };

        window.addEventListener('message', handleViaFrameMessages);

        // clean up
        return () => window.removeEventListener('message', handleViaFrameMessages);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user, viaFrameRef, viaFrameHasLoaded, currentWsi]);

    useEffect(() => {
        if (typeof currentWsi !== 'undefined') {
            updateViaProjectOptions(currentWsi.id);
        }
    }, [user, currentWsi]);

    useEffect(() => {
        updateModelInferenceOptions();
    }, []);

    const retrieveViaProject = (userId: number, wsi: Wsi) =>
        auth_fetch(`${config.backend}/api/via/${wsi.id}/${userId}/`, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        });

    const sendToViaFrame = (payload: Record<string, unknown>) => {
        const contentWindow = viaFrameRef?.current?.contentWindow;
        if (contentWindow) {
            contentWindow.postMessage(payload, '*');
        }
    };

    // const handleWsiSelect = (selectedWsi: Wsi) => {
    //     setCurrentWsi(selectedWsi);
    //     // setIframeHasLoaded(false);
    // };

    const startSavingViaDataStore = () => {
        sendToViaFrame({
            action: 'retrieveDataStore', //ViaFrame will emit event with action "saveViaDataStore" and  event.data.viaDataStore
        });
    };

    const addCirclesToViaFrame = (circles: Array<Array<number>>) => {
        sendToViaFrame({
            action: 'addCircles',
            circles: circles,
        });
    };

    const addShapesToViaFrame = (shape: string, elements: Array<Array<number>>) => {
        if (shape === 'min_circle' || shape === 'max_circle') {
            sendToViaFrame({
                action: 'addCircles',
                circles: elements.map(shape === 'min_circle' ? bbox2mincircle : bbox2maxcircle),
            });
        } else if (shape === 'ellipse') {
            sendToViaFrame({
                action: 'addEllipses',
                ellipses: elements.map(bbox2ellipse),
            });
        } else if (shape === 'rect') {
            sendToViaFrame({
                action: 'addRectangles',
                rectangles: elements.map(bbox2rect),
            });
        }
    };

    useEffect(() => {
        const fetchAllViaAnnotations = async () => {
            const allViaAnnotationsPromise = retrieveViaProjects();
            setAllViaAnnotations(await (await allViaAnnotationsPromise).json());
        };
        fetchAllViaAnnotations();
    }, []);

    useEffect(() => {
        const distictUsersAnnotations = [
            ...filterDistinct(allViaAnnotations, (a: ViaAnnotation, b: ViaAnnotation) => a.annotator === b.annotator),
        ];
        const usersWithAnnotationsOptions = new Set<Option>();
        distictUsersAnnotations.forEach((annotation: ViaAnnotation) => {
            usersWithAnnotationsOptions.add({ value: String(annotation.annotator), label: annotation.annotatorName });
        });
        setUsersWithAnnotationsOptions(usersWithAnnotationsOptions);
    }, [allViaAnnotations]);

    useEffect(() => {
        if (currentDataset) {
            const updateAvailableWsi = async (datasetId: number, annotationFilter: Cnf<Option>) => {
                const wsiInDatasetPromise = retrieveWsi(datasetId);
                if (annotationFilter === null) {
                    const wsiInDataset = await (await wsiInDatasetPromise).json();
                    // setWsiInCurrentDataset(wsiInDataset)
                    setAvailableWsi(wsiInDataset);
                } else {
                    const wsiInDataset = await (await wsiInDatasetPromise).json();
                    // setWsiInCurrentDataset(wsiInDataset)
                    const annotatedByUserIdFilter = (userId: number, wsi: Wsi) =>
                        allViaAnnotations.findIndex(
                            (annotation: ViaAnnotation) => annotation.wsi === wsi.id && annotation.annotator === userId,
                        ) !== -1;

                    // annotationFilter.forEach((o: Option, idx) => {
                    //     if (o.value !== '0') {
                    //         if (filterMode === 'AND') {
                    //             wsiInDataset = wsiInDataset.filter(annotatedByUserIdFilter.bind(parseInt(o.value)));
                    //         } else {
                    //             const ids = new Set(wsiInDataset.map((wsi: Wsi) => wsi.id));
                    //             wsiInDataset = [
                    //                 ...wsiInDataset,
                    //                 ...wsiInDataset
                    //                     .filter((wsi: Wsi) => !ids.has(wsi.id))
                    //                     .filter(annotatedByUserIdFilter.bind(parseInt(o.value))),
                    //             ];
                    //         }
                    //     }
                    // });

                    setAvailableWsi(wsiInDataset);
                    // if (annotationFilter === WsiAnnotationFilter.ONLY_ANNOTATED) {
                    //     const annotatedWsiFilter = (wsi: Wsi) =>
                    //         allViaAnnotations.findIndex((annotation: ViaAnnotation) => annotation.wsi === wsi.id) !== -1;
                    //     setAvailableWsi(wsiInDataset.filter(annotatedWsiFilter));
                    // } else if (annotationFilter === WsiAnnotationFilter.ONLY_NON_ANNOTATED) {
                    //     const notAnnotatedWsiFilter = (wsi: Wsi) =>
                    //         allViaAnnotations.findIndex((annotation: ViaAnnotation) => annotation.wsi === wsi.id) === -1;
                    //     setAvailableWsi(wsiInDataset.filter(notAnnotatedWsiFilter));
                    // }
                }
            };

            updateAvailableWsi(currentDataset.id, filterWsiAnnotatedBy);
        }
    }, [currentDataset, filterWsiAnnotatedBy]);
    // (selectedWsi) => this.changeWsi(selectedWsi)

    const checkboxProps = [
        {
            value: WsiAnnotationFilter.ONLY_ANNOTATED,
            label: 'Show only annotated WSI',
            className: styles.checkboxInCardHeader,
            inline: true,
        },
        {
            value: WsiAnnotationFilter.ONLY_NON_ANNOTATED,
            label: 'Show WSI without annotation',
            className: styles.checkboxInCardHeader,
            inline: true,
        },
    ];

    const updateClause = (clause: Clause<Option>, option: Option) => {
        clause.setNegated(option.value === '0');

        setFilterWsiAnnotatedBy(filterWsiAnnotatedBy.clone());
    };

    return (
        <Container fluid className="main-content-container px-4">
            <Row noGutters className="page-header py-4">
                <PageTitle title="Cell annotation" subtitle="Slide images" md="12" className="ml-sm-auto mr-sm-auto" />
            </Row>
            <Row>
                <Col lg="12" md="12" className="mb-4">
                    <Card small>
                        <CardHeader className="border-bottom">
                            <h6 className="m-0">Select dataset</h6>
                            <div className="block-handle" />
                        </CardHeader>

                        <CardBody className="border-top">
                            <DatasetSelect
                                selectedDataset={currentDataset}
                                onChange={(ds) => {
                                    setCurrentWsi(undefined);
                                    setCurrentDataset(ds);
                                }}
                            />
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col lg="12" md="12" className="mb-4">
                    <Card small>
                        <CardHeader className="border-bottom">
                            <h6 className="m-0">
                                Select WSI
                                {/* <FormCheckbox key={0} inline={true} className={'test'} checked={true}>
                                    Annotated by any of these users:
                                </FormCheckbox>
                                <DynamicCnfSelectGroup
                                    cnf={filterWsiAnnotatedBy}
                                    updateClause={updateClause}
                                    text="annotated by ANY of these users"
                                    conjunctionPrefixText="AND"
                                ></DynamicCnfSelectGroup> */}
                                {/* <OneOrNoneCheckboxGroup
                                    selectedValue={filterAnnotatedWsi}
                                    setSelectedValue={setFilterAnnotatedWsi}
                                    checkboxesProps={checkboxProps}
                                ></OneOrNoneCheckboxGroup> */}
                            </h6>
                            <div className="block-handle" />
                        </CardHeader>

                        <CardBody className="border-top">
                            <WsiSelect availableWsi={availableWsi} selectedWsi={currentWsi} onChange={setCurrentWsi} />
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            {typeof currentWsi !== 'undefined' && (
                <div>
                    {/* <Row>
                        <Col lg="12" md="12" className="mb-4">
                            <CircleForm circleHandler={addCirclesToViaFrame} wsi={currentWsi} />
                        </Col>
                    </Row> */}
                    <Row>
                        <Col lg="12" md="12" sm="12" className="mb-4">
                            <Card>
                                <CardBody>
                                    <ModelInference
                                        availableModelOptions={availableModelInferenceOptions}
                                        wsi={currentWsi}
                                        shapeHandler={addShapesToViaFrame}
                                    ></ModelInference>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg="12" md="12" sm="12" className="mb-4">
                            <Card>
                                <CardBody>
                                    <ViaProjectImport
                                        availableUserProjectOptions={availableUserProjectOptions}
                                        wsi={currentWsi}
                                        importHandler={(userId: number) => updateViaFrame(userId, currentWsi)}
                                    ></ViaProjectImport>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg="12" md="12" sm="12" className="mb-4">
                            <Card>
                                <CardBody>
                                    <Button theme="primary" onClick={startSavingViaDataStore}>
                                        Save project in backend
                                    </Button>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg="12" md="12" sm="12" className="mb-4"></Col>
                        <ViaFrame
                            // onLoad={() => setIframeHasLoaded(true)}
                            src={process.env.PUBLIC_URL + 'via_image_annotator.html'}
                            ref={viaFrameRef}
                        ></ViaFrame>
                    </Row>
                </div>
            )}
        </Container>
    );
};

// {
/* <FormCheckbox
inline
className={styles.checkboxInCardHeader}
checked={filterAnnotatedWsi === WsiAnnotationFilter.ONLY_ANNOTATED}
onChange={() =>
    setFilterAnnotatedWsi(
        filterAnnotatedWsi === WsiAnnotationFilter.ONLY_ANNOTATED
            ? WsiAnnotationFilter.ALL
            : WsiAnnotationFilter.ONLY_ANNOTATED,
    )
}
>
Show only annotated WSI
</FormCheckbox>
<FormCheckbox
inline
className={styles.checkboxInCardHeader}
checked={filterAnnotatedWsi === WsiAnnotationFilter.ONLY_NON_ANNOTATED}
onChange={() =>
    setFilterAnnotatedWsi(
        filterAnnotatedWsi === WsiAnnotationFilter.ONLY_NON_ANNOTATED
            ? WsiAnnotationFilter.ALL
            : WsiAnnotationFilter.ONLY_NON_ANNOTATED,
    )
}
>
Show only not annotated WSI
</FormCheckbox> */
// }

export default SegmentationAnnotationErythrocytes;
