import React, { createContext, Dispatch, ReactNode, SetStateAction, useState } from 'react';

export type ReportDataRow = {
    label: string;
    count: number;
    percentage: number;
}

export type ReportMeta = {
    filename: string;
}

export type Report = {
    meta: ReportMeta;
    data: ReportDataRow[];
}

export const defaultReportContext: Report = {
    data: [],
    meta: {
        filename: ''
    }
};

export const defaultReportDispatchContext = () => {};

export const ReportContext = createContext<Report>(defaultReportContext);
export const ReportDispatchContext = createContext<Dispatch<SetStateAction<any>>>(defaultReportDispatchContext);