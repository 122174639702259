import React from 'react';
import { Page,  Image, Text, View, Document, StyleSheet } from '@react-pdf/renderer';
import { Report, defaultReportContext, ReportContext, ReportDispatchContext } from 'context/ReportContext';

// Create styles
const styles = StyleSheet.create({
    page: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        backgroundColor: '#fff',
        margin: 10,
        padding: 10,
    },
    header: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        backgroundColor: '#ddd',
        border: '1px solid #000',
    },
    logo: {
        width: "15%",
        padding: 10,
        textAlign: "center",
        border: '1px solid #000',
    },
    cancilicoMyeloaid: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        backgroundColor: '#ddd',
        width: "81%",
    },
    cancilico: {
        width: "100%",
        textAlign: "center",
        padding: 5,
    },
    myeloaid: {
        width: "100%",
        textAlign: "center",
        padding: 5,
    },
    metaTitles: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        backgroundColor: '#ddd',
        border: '1px solid #000',
    },
    metaEmpty: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        backgroundColor: '#fff',
        border: '1px solid #000',
        height: 30,
    },
    number: {
        width: "24%",
        borderRight: '1px solid #000',
        padding: 5,
    },
    name: {
        width: "48%",
        borderRight: '1px solid #000',
        padding: 5,
    },
    study: {
        width: "24%",
        borderRight: '1px solid #000',
        padding: 5,
    },
    timestamp: {
        width: "48%",
        padding: 5,
    },
    myelogramTable: {
        border: '1px solid #000',
    },
    myelogramHeaderRow: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        backgroundColor: '#ddd',
    },
    myelogramRow: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        backgroundColor: '#fff',
    },
    cellTypeContent: {
        borderRight: '1px solid #000',
        padding: 5,
        width: "32%"
    },
    countContent: {
        borderRight: '1px solid #000',
        padding: 5,
        width: "32%"
    },
    percentageContent: {
        // borderRight: '1px solid #000',
        padding: 5,
        width: "32%"
    },
    findingsContent: {
        padding: 5,
        width: "24%"
    },
});

// Create Document Component
const ReportPDF = ({report}: {report: Report}) => (
  <Document>
    <Page size="A4" style={styles.page}>
        <View style={styles.header}>
            <View style={styles.logo}><Image src="https://demo.cancilico.de/myeloaid-demo/myeloaid-logo.png"></Image></View>
            <View style={styles.cancilicoMyeloaid}>
                <View style={styles.cancilico}><Text>Cancilico</Text></View>
                <View style={styles.myeloaid}><Text>MyeloAID</Text></View>
            </View>
        </View>
        <View style={styles.metaTitles}>
            <View style={styles.name}><Text>Filename</Text></View>
            <View style={styles.timestamp}><Text>Generated</Text></View>
        </View>
        <View style={styles.metaEmpty}>
            <View style={[styles.name, {height: 30}]}><Text>{report.meta.filename}</Text></View>
            <View style={[styles.timestamp, {height: 30}]}><Text>{new Date().toLocaleString()}</Text></View>
        </View>
        {/* <View style={styles.metaTitles}>
            <View style={styles.number}><Text>Number</Text></View>
            <View style={styles.name}><Text>Name</Text></View>
            <View style={styles.study}><Text>Study</Text></View>
            <View style={styles.timestamp}><Text>Time</Text></View>
        </View> 
        <View style={[styles.metaEmpty]}>
            <View style={[styles.number, {height: 30}]}><Text>&nbsp;</Text></View>
            <View style={[styles.name, {height: 30}]}><Text>&nbsp;</Text></View>
            <View style={[styles.study, {height: 30}]}><Text>&nbsp;</Text></View>
            <View style={[styles.timestamp, {height: 30}]}><Text>&nbsp;</Text></View>
        </View>*/}
        <View style={styles.myelogramTable}>
            <View style={styles.myelogramHeaderRow}>
                <View style={styles.cellTypeContent}><Text>Cell Type</Text></View>
                <View style={styles.countContent}><Text>Count</Text></View>
                <View style={styles.percentageContent}><Text>%</Text></View>
            </View>
            {report.data.map((row, index) => (
                <View key={index} style={[styles.myelogramRow, { backgroundColor: row.label === 'Blast' && row.percentage > 10 ? 'red' : 'white'}]}>
                    <View style={styles.cellTypeContent}><Text>{row.label}</Text></View>
                    <View style={styles.countContent}><Text>{row.count}</Text></View>
                    <View style={styles.percentageContent}><Text>{row.percentage.toFixed(1)}%</Text></View>
                </View> 
            ))}
            {/* <View style={styles.myelogramRow}>
                <View style={[styles.cellTypeContent]}><Text style={{textDecoration: "underline"}}></Text></View>
                <View style={[styles.countContent]}><Text style={{textDecoration: "underline"}}>500</Text></View>
                <View style={[styles.percentageContent]}><Text style={{textDecoration: "underline"}}>100%</Text></View>
                <View style={[styles.findingsContent]}><Text style={{textDecoration: "underline"}}></Text></View>
            </View> */}
        </View>


        {/* <View style={styles.row}>
            <Text >LabelA</Text>
            <View style={styles.rightColumn}>
                <Text style={styles.righColumnInner}>LabelB</Text>
                <Text style={styles.righColumnInner}>LabelC</Text>
            </View> 
        </View>
        <View style={styles.row}>
            <Text >LabelD</Text>
            <View style={styles.rightColumn}>
                <Text style={styles.righColumnInner}>LabelE</Text>
                <Text style={styles.righColumnInner}>LabelF</Text>
            </View>         
        </View>

        {report.data.map((row, index) => (
            <View key={index} style={styles.row}>
                <Text style={styles.flexitem}>{row.label}</Text>
                <View style={styles.flexitem}>
                    <Text style={styles.flexitem}>{row.count}</Text>
                    <Text style={styles.flexitem}>{row.percentage}</Text>
                </View>
            </View>
        ))} */}
    </Page>
  </Document>
);

export default ReportPDF;