// Packages
import Map from 'components/openlayers/src/ol/Map';
import VectorLayer from 'components/openlayers/src/ol/layer/Vector';
import VectorSource from 'components/openlayers/src/ol/source/Vector';
import Geometry from 'components/openlayers/src/ol/geom/Geometry';

import createSimpleId from '../lib/utils/createSimpleId';

// Config
import aidaConfig from '../aida.config';
import wsiConfig from '../config';

// Types
import { Annotation } from '../models/annotation';
import type Point from 'components/openlayers/src/ol/geom/Point';
import type LineString from 'components/openlayers/src/ol/geom/LineString';
import type Polygon from 'components/openlayers/src/ol/geom/Polygon';
import { auth_fetch } from '../utils/ajax';
import { UserId, WsiId } from '../models/BackendModels';
import Feature from 'components/openlayers/src/ol/Feature';

type FeatureGeom = Point | LineString | Polygon;

// Initial default template for new annotation data
const defaultAnnotation: Annotation = {
    header: {
        schemaVersion: '2.0',
        timestamp: Date.now(),
    },
    layers: [],
    classes: [],
};

// Save the annotation data
export const buildAnnotationData = (map: Map) => {
    const annotation = { ...defaultAnnotation };

    // Update header timestamp
    annotation.header.timestamp = Date.now();

    // Add feature classes
    const featureClasses = map.get('featureClasses');
    annotation.classes = Object.values(featureClasses);

    // Add layers
    const layers = map.getLayers();
    const annotationLayers = layers.getArray().filter((layer) => layer.get('type') === 'annotation');

    annotation.layers = annotationLayers.map((layer) => {
        const source = (layer as VectorLayer<VectorSource<Feature<Geometry>>>).getSource();
        const features = source ? source.getFeatures() : [];

        // Extract features
        // TODO: fix type problems with the below when using Feature[]
        const layerFeatures: any[] = features.flatMap((feature) => {
            const geometry = feature.getGeometry();

            if (geometry) {
                const geometryType = geometry.getType();
                const geometryCoordinates = (geometry as FeatureGeom).getCoordinates();

                return {
                    id: feature.get('id') || createSimpleId(),
                    class: feature.get('class'),
                    geometry: {
                        type: geometryType,
                        coordinates: geometryCoordinates,
                    },
                };
            } else return [];
        });

        return {
            id: layer.get('id'),
            features: layerFeatures,
        };
    });

    return annotation;

    // Extract path from window URL
    // const pathname = window.location.pathname

    // // Default annotation path
    // let annotationPath = pathname.replace(/\.[^.]+$/, '.json')

    // // If path ends in .json we assume open a project file. Therefore, we need to
    // // find and adjust the correct path for the annotation data.
    // if (pathname.endsWith('.json')) {
    // 	const projectResponse = await fetch(
    // 		`http://${window.location.hostname}:${config.server.port}/data${pathname}`
    // 	)
    // 	if (projectResponse.ok) {
    // 		const projectResponseJson = await projectResponse.json()
    // 		annotationPath = projectResponseJson.annotation
    // 	}
    // }

    // Send request
    // if(typeof endpoint === "undefined") {
    // 	const host = `http://${window.location.hostname}:${config.server.port}`
    // 	endpoint = `${host}/save`;
    // }

    // try {
    // 	await fetch(endpoint, {
    // 		method: 'POST',
    // 		headers: {
    // 			'Content-Type': 'application/json',
    // 		},
    // 		body: JSON.stringify({
    // 			annotationPath,
    // 			annotationData: annotation,
    // 		}),
    // 	})
    // 	map.set('unsavedChanges', false)
    // } catch (err) {
    // 	console.error(err)
    // }
};

export const buildSaveFunction = (userId: UserId, activeWsiId: WsiId) => {
    return async (map: Map) => {
        const annotation = buildAnnotationData(map);
        try {
            await auth_fetch(`${wsiConfig.backend}/api/aida/${activeWsiId}/${userId}/`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    overwrite: true,
                    aidaProject: annotation,
                }),
            });
            map.set('unsavedChanges', false);
        } catch (err) {
            console.error(err);
        }
    };
};
