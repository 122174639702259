import Viewer from '../components/viewer';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Annotation, FeatureClass, Style } from '../models/annotation';
import { buildSaveFunction } from '../api/save';
import { UserContext } from '../context/UserContext';
import { User, Wsi } from '../models/BackendModels';
import WsiSelect from '../components/wsi/WsiSelect';
import {
    Container,
    Row,
    Col,
    Button,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    FormCheckbox,
    FormRadio,
} from 'shards-react';
import { auth_fetch, checkSuccess } from '../utils/ajax';
import config from '../config';
import RadioButtons from '../components/components-overview/RadioButtons';
import ScriptTag from 'react-script-tag';
import { Canvas } from '../components/brush/Canvas';
import { usePainter } from '../hooks/usePainter';
// import { WickBrush } from '../components/plugins/wickbrush/WickBrush';
import styles from '../components/brush/brush.css';
import { Toolbar } from '../components/brush/Toolbar';
import WsiBrushEditor from '../components/wsi-brush-editor';

const retrieveAllWsi = (): Promise<Wsi[]> => auth_fetch(`${config.backend}/api/true_wsi/`).then(checkSuccess);

const retrieveAnnotationData = (wsiId: number, annotatorId: number): Promise<Annotation> =>
    auth_fetch(`${config.backend}/api/aida/${wsiId}/${annotatorId}/`, {
        headers: { 'Content-Type': 'application/json' },
    })
        .then(checkSuccess)
        .then((data) => JSON.parse(data.aidaProject.replaceAll("'", '"')));

const retrieveWsi = (wsiId?: number): Promise<Wsi> =>
    auth_fetch(`${config.backend}/api/true_wsi/${wsiId}/`)
        .then(checkSuccess)
        .then((data) => data[0]);

// Initial default template for new annotation data
const defaultAnnotation: Annotation = {
    header: {
        schemaVersion: '2.0',
        timestamp: Date.now(),
    },
    layers: [
        {
            id: 'Whole Slide',
            features: [],
        },
    ],
    classes: [
        // {
        //     id: 0,
        //     name: 'ROI',
        //     style: {
        //         stroke: {
        //             color: [132, 204, 22, 1],
        //             width: 1.25,
        //         },
        //         fill: {
        //             color: [132, 204, 22, 0.4],
        //         },
        //     },
        // },
    ],
};

class AnnotationMode implements FeatureClass {
    constructor(public id: number, public name: string, public style: Style) {}
}

const roiMode = new AnnotationMode(1, 'ROI', {
    stroke: {
        color: [132, 204, 22, 1],
        width: 1.25,
    },
    fill: {
        color: [132, 204, 22, 0.4],
    },
});

const invertedRoiMode = new AnnotationMode(2, 'Inverted ROI', {
    stroke: {
        color: [220, 1, 34, 1],
        width: 1.25,
    },
    fill: {
        color: [220, 1, 34, 0.4],
    },
});

const megakaryocyteMode = new AnnotationMode(3, 'Megakaryocytes', {
    stroke: {
        color: [16, 5, 156, 1],
        width: 1.25,
    },
    fill: {
        color: [16, 5, 156, 0.4],
    },
});

defaultAnnotation.classes = [
    Object.assign({}, roiMode),
    Object.assign({}, invertedRoiMode),
    // Object.assign({}, megakaryocyteMode),
];

const Demo = () => {
    const { user } = useContext(UserContext);
    // const [annotationMode, setAnnotationMode] = useState<AnnotationMode>();
    const [availableWsi, setAvailableWsi] = useState<Wsi[]>([]);
    const [selectedWsi, setSelectedWsi] = useState<Wsi>();
    const [annotationData, setAnnotationData] = useState<Annotation>(defaultAnnotation);
    const [viewerKey, setViewerKey] = useState(0);
    const [loaded, setLoaded] = useState(false);

    // useEffect(() => {
    // if(typeof selectedWsi !== 'undefined' && typeof annotationMode !== 'undefined' && loaded) {
    //     debugger;
    //     let canvas = document.querySelector("#roi-wsi-viewer canvas");
    //     let brush = new WickBrush({
    //         canvas: canvas
    //     });
    // }
    // }, [selectedWsi, annotationMode, loaded]);

    const retrieveAnnotationDataOrDefault = async (wsi: Wsi, annotator: User) => {
        let wsiAnnotationData = defaultAnnotation;
        try {
            wsiAnnotationData = await retrieveAnnotationData(wsi.id, annotator.id);
        } catch (e) {
            console.log(`No annotation data found for this WSI, creating new annotation data ${e}`);
        }
        return wsiAnnotationData;
    };

    // const setModeFromAnnotationData = (data: Annotation) => {
    //     if (typeof data.classes[0] === 'undefined') {
    //         setAnnotationMode(undefined);
    //     } else {
    //         if (data.classes[0].id === 1) {
    //             setAnnotationMode(roiMode);
    //         } else if (data.classes[0].id === 2) {
    //             setAnnotationMode(invertedRoiMode);
    //         } else {
    //             setAnnotationMode(undefined);
    //         }
    //     }
    // };

    const changeWsi = async (wsi: Wsi) => {
        const data = await retrieveAnnotationDataOrDefault(wsi, user);
        // setModeFromAnnotationData(data);
        setAnnotationData(data);
        setSelectedWsi(wsi);
        setViewerKey(viewerKey + 1);
    };

    // const changeAnnotationMode = (mode: AnnotationMode) => {
    // if (
    // typeof annotationMode === 'undefined' ||
    // window.confirm('This will reset all your annotations for this slide. Are you sure?')
    // ) {
    // setAnnotationMode(mode);
    //         const annotation = Object.assign({}, defaultAnnotation);
    //         annotation.classes = [mode];
    //         setAnnotationData(annotation);
    //         setViewerKey(viewerKey + 1);
    //     }
    // };

    const save = useMemo(
        () =>
            typeof selectedWsi !== 'undefined'
                ? buildSaveFunction(user.id, selectedWsi.id)
                : async () => {
                      console.log('Empty save function');
                  },
        [user, selectedWsi],
    );

    useEffect(() => {
        retrieveAllWsi().then(async (wsi: Wsi[]) => {
            // wsi = wsi.filter((w) => w.id > 57 || w.id === 41 || w.id === 9 || w.id === 34 || w.id === 47);
            // wsi = wsi.filter(w => w.datasetId === 8) // === w.id >= 75 && w.id <= 101)
            wsi = wsi.filter((w) => w.id === 102);
            setAvailableWsi(wsi);
            const data = await retrieveAnnotationDataOrDefault(wsi[0], user);
            // setModeFromAnnotationData(data);
            setAnnotationData(data);
            setSelectedWsi(wsi[0]);
        });
    }, []);
    // Demo uses example image from S3 bucket
    return (
        <div id="roi-wsi-viewer">
            <Container>
                <Row>
                    <Col lg="11" md="11" className="mb-4 offset-1">
                        <Card small>
                            <CardHeader className="border-bottom">
                                <h6 className="m-0">Select whole slide image</h6>
                                <div className="block-handle" />
                            </CardHeader>

                            <CardBody className="border-top">
                                <WsiSelect
                                    isDisabled={false}
                                    availableWsi={availableWsi}
                                    selectedWsi={selectedWsi}
                                    onChange={changeWsi}
                                />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
            {typeof selectedWsi !== 'undefined' && (
                <WsiBrushEditor
                    key={viewerKey}
                    imageUrl={selectedWsi.webUrl}
                    imageExt={'dzi'}
                    annotationData={annotationData}
                    save={save}
                />
            )}
        </div>
    );
};

export default Demo;
