// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.subscript::after  {
    font-size: 0.7rem;
    position: relative;
    bottom: -0.3rem;
}

.pdf-link::after {
    color: white;
    content: 'pdf';
}

.csv-link::after {
    color: white;
    content: 'csv';
}`, "",{"version":3,"sources":["webpack://./src/layouts/Myeloaid02.css"],"names":[],"mappings":";AACA;IACI,iBAAiB;IACjB,kBAAkB;IAClB,eAAe;AACnB;;AAEA;IACI,YAAY;IACZ,cAAc;AAClB;;AAEA;IACI,YAAY;IACZ,cAAc;AAClB","sourcesContent":["\n.subscript::after  {\n    font-size: 0.7rem;\n    position: relative;\n    bottom: -0.3rem;\n}\n\n.pdf-link::after {\n    color: white;\n    content: 'pdf';\n}\n\n.csv-link::after {\n    color: white;\n    content: 'csv';\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
