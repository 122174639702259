import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { SelectComponentsProps } from 'react-select/src/Select';
import styled from 'styled-components';
import { Dataset } from '../../models/BackendModels';
import config from './../../config';
import { checkSuccess, auth_fetch } from './../../utils/ajax';

interface DatasetSelectProps extends SelectComponentsProps {
    className?: string;
    selectedDataset?: Dataset;
    onChange: (selectedDataset: Dataset) => void;
    isDisabled?: boolean;
    filter?: (ds: Dataset) => boolean;
}

// Required to prevent typescript bug?
const StyledSelect = styled(Select)``;

class DatasetOption {
    public label = 'Dataset';
    public value = '0';
    public model?: Dataset;

    constructor(dataset?: Dataset) {
        if (dataset) {
            this.label = dataset.name;
            this.value = String(dataset.id);
            this.model = dataset;
        }
    }
}

const defaultDatasetOption = new DatasetOption();

export const retrieveDatasets = (): Promise<Response> =>
    auth_fetch(`${config.backend}/api/datasets/`, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
    });

const DatasetSelect: React.FC<DatasetSelectProps> = ({
    className,
    selectedDataset,
    onChange,
    isDisabled,
    filter = (ds: Dataset) => true,
}: DatasetSelectProps) => {
    // const [selectedOption, setSelectedOption] = useState(defaultDatasetOption);
    const [datasetsOptions, setDatasetOptions] = useState<DatasetOption[]>([defaultDatasetOption]);

    useEffect(() => {
        retrieveDatasets()
            .then(checkSuccess)
            .then((data) => setDatasetOptions(data.filter(filter).map((ds: Dataset) => new DatasetOption(ds))));
    }, []);
    const selectedOption = datasetsOptions.find((o) => o.value === String(selectedDataset?.id));
    return (
        <StyledSelect
            isDisabled={isDisabled}
            className={className}
            value={selectedOption ? selectedOption : defaultDatasetOption}
            placeholder="Dataset name"
            isSearchable={true}
            onChange={(o: DatasetOption) => (o.model ? onChange(o.model) : null)}
            options={datasetsOptions}
        />
    );
};

export default DatasetSelect;
