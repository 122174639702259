// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Common_checkboxInCardHeader__mm9gz {
    left: 50px;
    top: 7px;
    z-index: 0;
}`, "",{"version":3,"sources":["webpack://./src/views/Common.module.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,QAAQ;IACR,UAAU;AACd","sourcesContent":[".checkboxInCardHeader {\n    left: 50px;\n    top: 7px;\n    z-index: 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"checkboxInCardHeader": `Common_checkboxInCardHeader__mm9gz`
};
export default ___CSS_LOADER_EXPORT___;
