import React, { Component, useContext, useEffect, useState } from 'react';
import config from './../config';
import { auth_fetch } from './../utils/ajax';
import {
    Container,
    Row,
    Col,
    Button,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    FormCheckbox,
    FormRadio,
} from 'shards-react';
import PageTitle from './../components/common/PageTitle';
import SingleCellDisplay, { fetchLabelGroups } from './../components/wsi/SingleCellDisplay';
import WsiDisplay from './../components/wsi/WsiDisplay';
//import SingleCellAnnotation from "./../components/wsi/SingleCellAnnotation";
import { Cell, Dataset, LabelGroup, LabelSet, Label, Wsi, ExportGroup } from './../models/BackendModels';
import DatasetSelect from './../components/wsi/DatasetSelect';
import WsiSelect from './../components/wsi/WsiSelect';
import { UserContext } from './../context/UserContext';
import { checkSuccess } from './../utils/ajax';
import { type } from 'os';
import ClipLoader from 'react-spinners/ClipLoader';
import Input from 'react-select/src/components/Input';
import styled from 'styled-components';
import ExportGroupSelect, {
    retrieveExportGroup,
    retrieveExportGroups,
    retrieveExportGroupWsi,
} from '../components/wsi/ExportGroupSelect';

type CellId = number;

const SpacedCardBody = styled(CardBody)`
    display: flex;
    gap: 10px;
`;
const FilterButton = styled(Button)``;
// interface CellQueryResultItem {
//     id: number;
//     dataset: number;
//     wsi: number;
//     segmentationSet: number;
// }

interface FilterArgs {
    segmentationSetId?: number;
    datasetId?: number;
    wsiId?: number;
    labeledBy?: Array<number | null>;
    labelSetId: number;
    labelGroupIds?: number[];
}

export function retrieveLabelSet(labelSetId: number): Promise<LabelSet> {
    return auth_fetch(`${config.backend}/api/labelset/${labelSetId}/`).then(checkSuccess);
}

export const retrieveCellFilterSet = (
    cellFilterSetId: number,
    annotatorPriority: (number | null)[],
    userId: number | null = null,
): Promise<CellId[]> =>
    auth_fetch(`${config.backend}/api/cell_filterset/`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            cellFilterSetId: cellFilterSetId,
            annotatorPriority: annotatorPriority,
            tailoredForAnnotator: userId,
        }),
    }).then(checkSuccess);

export const retrieveWsi = (wsiId: number): Promise<Wsi> =>
    auth_fetch(`${config.backend}/api/wsi/${wsiId}`)
        .then(checkSuccess)
        .then((data) => data[0]);

const fetchCellQueryResult = (filterArgs: FilterArgs): Promise<CellId[]> => {
    return auth_fetch(`${config.backend}/api/query_cells/`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(filterArgs),
    }).then(checkSuccess);
};

const fetchCell = (cellId: number): Promise<Cell> => {
    return auth_fetch(`${config.backend}/api/cell/${cellId}/`).then(checkSuccess);
};

const fetchAnnotationCount = (
    userId: number,
    segmentationSetId: number,
    labelGroupId: number | undefined = undefined,
): Promise<number> => {
    return auth_fetch(
        `${config.backend}/api/cell_label_selections_count/${userId}/${segmentationSetId}/${
            typeof labelGroupId !== 'undefined' ? labelGroupId + '/' : ''
        }`,
    ).then(checkSuccess);
};

interface Option {
    id: number | null;
    name: string;
}
// const VALID_ANNOTATOR_OPTIONS = [
//     {
//         id: null,
//         name: 'Nobody',
//     },
//     {
//         id: 12,
//         name: 'Junker Jack',
//     },
//     {
//         id: 26,
//         name: 'Blair Brook',
//     },
//     {
//         id: 29,
//         name: 'Casey Carr',
//     },
//     {
//         id: 30,
//         name: 'Riley Ray',
//     },
// ];

const FIXED_SUGGESTIONS = [
    {
        id: 13,
        name: 'Cell',
        labelGroup: 8,
    },
    {
        id: 6,
        name: 'Erythrocyte',
        labelGroup: 5,
    },
];

// const FIXED_EXPORT_GROUPS = [44, 45];

const toggleSidebar = () => {
    const sidebar = document.querySelectorAll<HTMLElement>('.main-sidebar')[0];
    if (sidebar.style.transform === `translateX(-100%)`) {
        sidebar.style.transform = 'translateX(0)';
    } else {
        sidebar.style.transform = 'translateX(-100%)';
    }
};

const CELL_TYPE_LABEL_GROUP = 5;
const LINEAGE_LABEL_GROUP = 9;

const PreselectedCellAnnotation: React.FC = () => {
    const { user } = useContext(UserContext);
    // const [availableExportGroups, setAvailableExportGroups] = useState<ExportGroup[]>([]);
    // const [selectedExportGroup, setSelectedExportGroup] = useState<ExportGroup>();
    // const [availableWsi, setAvailableWsi] = useState<Wsi[]>([]);
    const [selectedWsi, setSelectedWsi] = useState<Wsi>();
    const [labelSet, setLabelSet] = useState<LabelSet>();
    // const [labeledBy, setLabeledBy] = useState([]);
    const [suggestions, setSuggestions] = useState<Label[]>(FIXED_SUGGESTIONS);
    // const [validFilterOptions, setValidFilterOptions] = useState<Set<Option>>(new Set(VALID_ANNOTATOR_OPTIONS));
    // const [priorityOption, setPriorityOption] = useState<Option>(); // VALID_ANNOTATOR_OPTIONS[0]
    const [myLabeledCells, setMyLabeledCells] = useState<CellId[]>([]);
    const [cellsFilter, setCellsFilter] = useState<CellId[]>([]);
    const [loaded, setLoaded] = useState(false);
    const [autoSkipLabeledByMe, setAutoSkipLabeledByMe] = useState(true);
    const [skipLabeledByMe, setSkipLabeledByMe] = useState(false);
    const [finalLoaded, setFinalLoaded] = useState(false);
    const [selectedCellIndex, setSelectedCellIndex] = useState(0);
    const [selectedCell, setSelectedCell] = useState<Cell>();
    const [cells, setCells] = useState<Cell[]>([]);
    const [cellTypeAnnotationCount, setCellTypeAnnotationCount] = useState<number>(0);
    const [annotationCount, setAnnotationCount] = useState<number>(0);
    // const [me, setMe] = useState<Option>();

    // useEffect(() => {
    //     if (typeof user !== 'undefined') {
    //         let my = VALID_ANNOTATOR_OPTIONS.find(u => u.id === user.id);
    //         // setMe({...user, name: "Me"});
    //         const me = {...user, name: `Me ${typeof my !== 'undefined' ? '('+my.name+')' : ''}`};
    //         setValidFilterOptions(new Set(VALID_ANNOTATOR_OPTIONS.filter(u => u.id !== user.id).concat(me)));
    //     }
    // }, [user]);

    useEffect(() => {
        retrieveLabelSet(config.labelSet).then((labelSet: LabelSet) => {
            setLabelSet(labelSet);
            // debugger;
        });
    }, []);

    useEffect(() => {
        setLoaded(false);
        retrieveCellFilterSet(3, [], user.id).then(setCellsFilter);
    }, []);

    // useEffect(() => {
    //     if (typeof priorityOption !== 'undefined') {
    //         setLoaded(false);
    //         retrieveCellFilterSet(1, [priorityOption.id]).then(setCellsFilter);
    //     }
    // }, [priorityOption]);

    // useEffect(() => {
    //     if (typeof user !== 'undefined') {
    //         setPriorityOption(
    //             user.id === 29
    //                 ? VALID_ANNOTATOR_OPTIONS.find((o) => o.id === 30)
    //                 : VALID_ANNOTATOR_OPTIONS.find((o) => o.id === 29),
    //         );
    //     }
    // }, [user]);

    useEffect(() => {
        if (autoSkipLabeledByMe && cellsFilter.length > 0 && myLabeledCells.length > 0) {
            let newIndex = selectedCellIndex + 1;
            while (autoSkipLabeledByMe && myLabeledCells.indexOf(cellsFilter[newIndex]) !== -1) {
                newIndex++;
            }
            setAutoSkipLabeledByMe(false);
            updateAnnotationCount();
            setSelectedCellIndex(newIndex);
        }
    }, [cellsFilter, myLabeledCells]);

    useEffect(() => {
        if (typeof cellsFilter !== 'undefined' && cellsFilter.length > 0) {
            setLoaded(false);
            fetchCellQueryResult({
                labelSetId: config.labelSet,
                labeledBy: [user.id],
                segmentationSetId: config.latestSegmentationSetId,
                labelGroupIds: [CELL_TYPE_LABEL_GROUP, LINEAGE_LABEL_GROUP],
            }).then(setMyLabeledCells);
        }
    }, [cellsFilter]);

    useEffect(() => {
        if (typeof myLabeledCells !== 'undefined' && myLabeledCells.length > 0) {
            setSelectedCellIndex(cellsFilter.findIndex((id) => myLabeledCells.indexOf(id) === -1));
        }
    }, [cellsFilter, myLabeledCells]);

    useEffect(() => {
        if (typeof cellsFilter !== 'undefined') {
            if (cellsFilter.length > 0 && typeof cellsFilter[selectedCellIndex] !== 'undefined') {
                if (skipLabeledByMe && myLabeledCells.indexOf(cellsFilter[selectedCellIndex]) !== -1) {
                    nextCell();
                } else {
                    setLoaded(false);
                    fetchCell(cellsFilter[selectedCellIndex]).then((cell) => {
                        setCells([cell]);
                        setSelectedCell(cell);
                    });
                }
                // .then(setSelectedCell)
                // .then(() => setFinalLoaded(true))
                // .then(() => setLoaded(true));
            } else {
                setCells([]);
                setSelectedCellIndex(0);
                setSelectedCell(undefined);
                // setFinalLoaded(true);
                // setLoaded(true);
            }
        }
    }, [cellsFilter, selectedCellIndex]);

    useEffect(() => {
        if (typeof selectedCell !== 'undefined') {
            retrieveWsi(selectedCell.wsi)
                .then(setSelectedWsi)
                .then(() => setFinalLoaded(true))
                .then(() => setLoaded(true));
        }
    }, [selectedCell]);

    const updateAnnotationCount = () => {
        fetchAnnotationCount(user.id, config.latestSegmentationSetId).then(setAnnotationCount);
        fetchAnnotationCount(user.id, config.latestSegmentationSetId, CELL_TYPE_LABEL_GROUP).then(
            setCellTypeAnnotationCount,
        );
    };

    useEffect(() => {
        updateAnnotationCount();
    }, [user]);

    const previousCell = () => {
        let newIndex = selectedCellIndex - 1;
        while (skipLabeledByMe && myLabeledCells.indexOf(cellsFilter[newIndex]) !== -1) {
            newIndex--;
        }
        updateAnnotationCount();
        setSelectedCellIndex(newIndex);
    };

    const nextCell = () => {
        let newIndex = selectedCellIndex + 1;
        while (skipLabeledByMe && myLabeledCells.indexOf(cellsFilter[newIndex]) !== -1) {
            newIndex++;
        }
        updateAnnotationCount();
        setSelectedCellIndex(newIndex);
    };

    const jumpToCell = (idx: number) => {
        updateAnnotationCount();
        setSelectedCellIndex(idx - 1);
    };

    const annotatedAsCellWithoutTypeCount = annotationCount - cellTypeAnnotationCount;

    return (
        <Container fluid className="main-content-container px-4" style={{ marginTop: '12vh' }}>
            <div style={{ textAlign: 'center' }}>
                <ClipLoader loading={!loaded} size={150} />
            </div>
            <Row>
                <Col lg="12" md="12" sm="12" className="mb-4">
                    {typeof selectedWsi !== 'undefined' &&
                        typeof selectedCell !== 'undefined' &&
                        typeof labelSet !== 'undefined' && (
                            <SingleCellDisplay
                                pixelDiameterInMicrometer={Number(selectedWsi.pixelDiameterInMicrometer)}
                                title={`Cell ${selectedCellIndex + 1} of ${cellsFilter.length} `}
                                user={user}
                                cell={selectedCell}
                                disabled={!loaded}
                                showPrevious={selectedCellIndex > 0}
                                showNext={selectedCellIndex < cellsFilter.length - 1}
                                onPrevious={previousCell}
                                onNext={nextCell}
                                onJumpTo={jumpToCell}
                                suggestions={suggestions}
                                labelSetId={labelSet.id}
                                annotationCountText={`You annotated a total of ${cellTypeAnnotationCount} cell types plus ${annotatedAsCellWithoutTypeCount} cells without type!`}
                                allowFreetext={true}
                                loaded={false}
                                hiddenLabelGroups={[11, 12]}
                            />
                        )}
                </Col>
            </Row>
            <Row>
                <Col className="d-block ">
                    {/* d-none d-lg-block */}
                    {typeof selectedWsi !== 'undefined' && (
                        <WsiDisplay
                            title="Whole slide image overlay"
                            loaded={finalLoaded}
                            wsi={selectedWsi}
                            downsize={true}
                            cells={cells}
                            pixelDiameterInMicrometer={Number(selectedWsi.pixelDiameterInMicrometer)}
                            selectedCell={selectedCell}
                            onClick={(cell: Cell) => setSelectedCellIndex(cellsFilter.indexOf(cell.id))}
                        />
                    )}
                </Col>
            </Row>
        </Container>
    );
};

export default PreselectedCellAnnotation;
