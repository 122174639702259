// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cell:hover {
  stroke: rgb(255, 0, 0, 0.1);
  fill: rgb(255, 0, 0, 0.1);
}

.cell.selected {
  /* stroke: rgb(64, 64, 64, 0.1); */
  /* stroke: rgb(255, 0, 0, 0.7); */
  /* fill: rgb(255, 0, 0, 0.7); */
}

.cell-tooltip__data {
  display: grid;
  grid: "key value" 1fr / 1fr 1fr;
  grid-column-gap: 5px;
}

`, "",{"version":3,"sources":["webpack://./src/components/wsi/WsiDisplay.css"],"names":[],"mappings":"AAAA;EACE,2BAA2B;EAC3B,yBAAyB;AAC3B;;AAEA;EACE,kCAAkC;EAClC,iCAAiC;EACjC,+BAA+B;AACjC;;AAEA;EACE,aAAa;EACb,+BAA+B;EAC/B,oBAAoB;AACtB","sourcesContent":[".cell:hover {\r\n  stroke: rgb(255, 0, 0, 0.1);\r\n  fill: rgb(255, 0, 0, 0.1);\r\n}\r\n\r\n.cell.selected {\r\n  /* stroke: rgb(64, 64, 64, 0.1); */\r\n  /* stroke: rgb(255, 0, 0, 0.7); */\r\n  /* fill: rgb(255, 0, 0, 0.7); */\r\n}\r\n\r\n.cell-tooltip__data {\r\n  display: grid;\r\n  grid: \"key value\" 1fr / 1fr 1fr;\r\n  grid-column-gap: 5px;\r\n}\r\n\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
