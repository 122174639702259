import { plainToClass } from 'class-transformer';
import React, { createContext, ReactNode } from 'react';
import { User } from '../models/BackendModels';
import config from './../config';
import { checkSuccess, PayloadError, auth_fetch } from './../utils/ajax';

// function setCookie(cname: string, cvalue: string, exdays = 365) {
//     const d = new Date();
//     d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
//     const expires = 'expires=' + d.toUTCString();
//     document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/';
// }

// function getCookie(cname: string) {
//     const name = cname + '=';
//     const ca = document.cookie.split(';');
//     for (let i = 0; i < ca.length; i++) {
//         let c = ca[i];
//         while (c.charAt(0) == ' ') {
//             c = c.substring(1);
//         }
//         if (c.indexOf(name) == 0) {
//             return c.substring(name.length, c.length);
//         }
//     }
//     return '';
// }

export function logout() {
    sessionStorage.removeItem('user');
    sessionStorage.removeItem('Authorization');
    window.location.replace('landingpage');
}

function checkLoginStatus() {
    const user = sessionStorage.getItem('user');
    if (user && window.location.href.includes('landingpage')) {
        window.location.replace('/preselectedcell-annotation');
    } else if (!user && !window.location.href.includes('landingpage')) {
        window.location.replace('/landingpage');
    }
    return user ? plainToClass(User, JSON.parse(user)) : new User();
}

function retrieveUserToken(username: string, password: string) {
    // return fetch(`${config.backend}/api/annotators/${username}`, {"method": "POST"});
    return fetch(`${config.backend}/api/token_auth/`, {
        method: 'POST',
        headers: {
            'content-type': 'application/json',
        },
        body: JSON.stringify({
            username: username,
            password: password,
        }),
    });
}

function retrieveAnnotatorByToken(token: string) {
    return auth_fetch(`${config.backend}/api/annotator_by_token/${token}/`);
}

function update(username: string, expertise: number) {
    return (data: Array<User>) => {
        if (data.length) {
            return updateUser(data[0], expertise);
        } else {
            throw new Error('User does not exist');
        }
    };
}

function updateOrCreate(username: string, expertise: number) {
    return (data: Array<User>) => {
        if (data.length) {
            return updateUser(data[0], expertise);
        } else {
            return createUser(username, expertise);
        }
    };
}

// export function requestUser() {
//     checkUserCookie();
//     return retrieveUser(getCookie('username'));
// }

function authorizationToken() {
    return sessionStorage.getItem('Authorization');
}

function rememberAuthToken(token: string) {
    sessionStorage.setItem('Authorization', `Token ${token}`);
}

function rememberLogin(user: string) {
    // data: Array<string> | string
    // JSON.stringify(Array.isArray(data) ? data[0] : data)
    sessionStorage.setItem('user', user);
    checkLoginStatus();
}

function extractUser(data: Array<string> | string) {
    return JSON.stringify(Array.isArray(data) ? data[0] : data);
}

function updateUser(user: User, selectedExpertise: number) {
    return auth_fetch(`${config.backend}/api/annotator/${user.id}/`, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
            id: user.id,
            name: user.name,
            forget_after_seconds: user.forgetAfterSeconds,
            default_confidence: user.defaultConfidence,
            expertise: selectedExpertise,
        }),
    });
}

function createUser(username: string, selectedExpertise: number) {
    return auth_fetch(`${config.backend}/api/annotators/`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
            name: username,
            //"forget_after_seconds": user.forget_after_seconds,
            //"default_confidence": user.default_confidence,
            expertise: selectedExpertise,
        }),
    });
}

// type UserContextProps = {
//     user: void;
//     login: (username: string, expertise: number) => Promise<void>;
// } | null;

type ChildrenProps = {
    children: ReactNode;
};

const DefaultUserContext = {
    user: checkLoginStatus(),
    authorization: authorizationToken(),
    login: async (username: string, password: string) => {
        const tokenData = await retrieveUserToken(username, password).then(checkSuccess);
        rememberAuthToken(tokenData['token']);
        const annotatorData = await retrieveAnnotatorByToken(tokenData['token']).then(checkSuccess);
        rememberLogin(JSON.stringify(Array.isArray(annotatorData) ? annotatorData[0] : annotatorData));
    },
};

export const UserContext = createContext(DefaultUserContext);

export const UserContextProvider: React.FC<ChildrenProps> = ({ children }: ChildrenProps) => {
    return <UserContext.Provider value={DefaultUserContext}>{children}</UserContext.Provider>;
};
