// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.add-new-post__editor .ql-container,
.add-new-post__editor .ql-editor {
  min-height: 400px;
}
`, "",{"version":3,"sources":["webpack://./src/assets/quill.css"],"names":[],"mappings":"AAAA;;EAEE,iBAAiB;AACnB","sourcesContent":[".add-new-post__editor .ql-container,\n.add-new-post__editor .ql-editor {\n  min-height: 400px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
