import PropTypes from 'prop-types';
import React from 'react';
import config from './../../config';
import ReactTooltip from 'react-tooltip';
import { Card, CardBody, CardHeader } from 'shards-react';
import './WsiDisplay.css';

const slideContainerScaleFactor = (originalImgWidth) => {
    const slideContainer = document.querySelector('#ref-container');
    if (slideContainer !== null) {
        return originalImgWidth / slideContainer.clientWidth;
    }
    return 2;
};

const getSelectedCellBBoxStyle = (selectedCell, svgContainer) => {
    let selectedCellRect = selectedCell.getBoundingClientRect();
    let padding = 1;

    return {
        left: selectedCellRect.left - svgContainer.getBoundingClientRect().left - padding + 15 + 'px',
        top: selectedCellRect.top - svgContainer.getBoundingClientRect().top - padding + 74 + 'px',
        width: selectedCellRect.width + 2 * padding + 'px',
        height: selectedCellRect.height + 2 * padding + 'px',
        position: 'absolute',
        border: '0.15vw solid red',
        borderRadius: '50%',
    };
};

const bbox2ellipse = (bbox) => {  
    const x_center = (bbox[1] + bbox[3]) / 2;
    const y_center = (bbox[0] + bbox[2]) / 2;
    const x_radius = (bbox[3] - bbox[1]) / 2;
    const y_radius = (bbox[2] - bbox[0]) / 2;
    return [y_center, x_center, y_radius, x_radius];
};

const supportsWebp = () => {
    var elem = document.createElement('canvas');

    if (!!(elem.getContext && elem.getContext('2d'))) {
        // was able or not to get WebP representation
        return elem.toDataURL('image/webp').indexOf('data:image/webp') == 0;
    } else {
        // very old browser like IE 8, canvas not supported
        return false;
    }
};

// const isSafari = /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof safari !== 'undefined' && safari.pushNotification));
const replaceExt = (path, newExt) => path.substr(0, path.lastIndexOf('.')) + newExt;

class WsiDisplay extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            colors: {},
        };
    }

    getColor(cell) {
        let r = 0,
            g = 0,
            b = 0;
        if (cell.lastAnnotationDate !== null) {
            g = 255;
            //Math.floor(200+Math.random() * 55);
        } else {
            r = g = b = 64; //Math.floor(Math.random() * 128);
        }
        if (!this.state.colors[cell.id]) {
            let colors = this.state.colors;
            colors[cell.id] = `rgb(${r},${g},${b},1.0)`;
            // this.setState({ colors: colors });
        }
        /*
    } */
        return this.state.colors[cell.id];
    }

    componentDidUpdate() {
        let selectedCell = document.querySelector('polygon.cell.selected');
        if (selectedCell !== null) {
            let svgContainer = document.querySelector('#wsi-svg-container');
            let selectedElementBbox = document.querySelector('#selected-element-bbox');
            let bboxStyle = getSelectedCellBBoxStyle(selectedCell, svgContainer);
            Object.assign(selectedElementBbox.style, bboxStyle);
        }
        // var $this = $(ReactDOM.findDOMNode(this));
        // set el height and width etc.
    }

    render() {
        const { title, wsi, cells, selectedCell, onClick, pixelDiameterInMicrometer, loaded, useWebp, downsize } = this.props;
        let originalImgWidth, originalImgHeight, scaleFactor, scaledWidth, scaledHeight, bboxStyle;
        if (loaded && downsize) {
            originalImgWidth = 3088;
            originalImgHeight = 2076;
            scaleFactor = slideContainerScaleFactor(originalImgWidth);
            scaledWidth = originalImgWidth / scaleFactor;
            scaledHeight = originalImgHeight / scaleFactor;
        }
        const wsiWebUrl = (useWebp && supportsWebp()) ? wsi.webUrl : replaceExt(wsi.webUrl, '.jpg');
        let header;
        if(title === '__HIDE__') {  
            header = false;
        } else if (title === '__PURE__') {
            header = title;
        } else {
            header = `${title} wsi:${wsi.id} ds: ${wsi.datasetId}` 
        }
        return (
            <Card small>
                {header && (
                    <CardHeader className="border-bottom">
                        <h6 className="m-0">
                            {header}
                        </h6>
                        <div className="block-handle" />
                    </CardHeader>
                )}

                <CardBody className="border-top">
                    <div id="ref-container">
                        <div id="selected-element-bbox" style={{ pointerEvents: 'none' }}>
                            &nbsp;
                        </div>
                        {loaded && (
                            <div id="wsi-svg-container">
                                <svg
                                    width={scaledWidth}
                                    height={scaledHeight}
                                    style={{
                                        backgroundImage: `url(${wsiWebUrl})`, //${require("../../data/wsi_images/"+wsi.imgName)}
                                        backgroundSize: `${scaledWidth}px ${scaledHeight}px`,
                                    }}
                                >
                                                                        <g>
                                        {cells.map((e, _) => {
                                            let c = this.getColor(e);
                                            let boundaryPoints = JSON.parse(e.boundaryPoints);
                                            let shape = null;
                                            if(typeof boundaryPoints === 'undefined' || boundaryPoints === null) {  
                                                let bbox = JSON.parse(e.bbox);
                                                if(typeof bbox === 'undefined' || bbox === null) {
                                                    return null
                                                }
                                                let ellipse = bbox2ellipse(bbox);
                                                shape = (
                                                    <ellipse
                                                        fill={`rgba(0,0,0,0.0)`}
                                                        stroke={c}
                                                        strokeWidth="3"
                                                        data-tip
                                                        data-for={e.id}
                                                        key={e.id}
                                                        className={'cell' + (selectedCell === e ? ' selected' : '')}
                                                        onClick={(_e) => onClick(e)}
                                                        cx={ellipse[0] / scaleFactor}
                                                        cy={ellipse[1] / scaleFactor}
                                                        rx={(ellipse[2]+10) / scaleFactor}
                                                        ry={(ellipse[3]+10) / scaleFactor}
                                                    />
                                                )
                                            } else {
                                                shape = (
                                                    <polygon
                                                        fill={c}
                                                        stroke={c}
                                                        strokeWidth="1"
                                                        data-tip
                                                        data-for={e.id}
                                                        key={e.id}
                                                        className={'cell' + (selectedCell === e ? ' selected' : '')}
                                                        onClick={(_e) => onClick(e)}
                                                        points={boundaryPoints
                                                            .map((p, _) => [p[1] / scaleFactor, p[0] / scaleFactor])
                                                            .join(' ')}

                                                    />
                                                )
                                            }
                                            return shape;
                                        })}
                                    </g>
                                </svg>
                                {cells.map((cell, i) => (
                                    <ReactTooltip key={cell.id} id={String(cell.id)} aria-haspopup="true">
                                        <div className="cell-tooltip__head">
                                            Cell {i + 1} of {cells.length}
                                        </div>
                                        <div className="cell-tooltip__data">
                                            <span>Last annotation: </span>
                                            <span>
                                                {cell.lastAnnotationDate !== null
                                                    ? 'On ' + cell.lastAnnotationDate
                                                    : 'Never'}
                                            </span>
                                            <span>Eccentricity:</span>
                                            <span>
                                                {cell.eccentricity !== null ? cell.eccentricity.toFixed(2) : ''}
                                            </span>
                                            <span>EquiDiameter:</span>
                                            <span>
                                                {(cell.equivalentDiameter * pixelDiameterInMicrometer).toFixed(2)}
                                                &#181;m
                                            </span>
                                            <span>Perimeter:</span>
                                            <span>
                                                {(cell.perimeter * pixelDiameterInMicrometer).toFixed(2)}&#181;m
                                            </span>
                                            <span>Area:</span>
                                            <span>
                                                {(cell.convexArea * Math.pow(pixelDiameterInMicrometer, 2)).toFixed(2)}
                                                &#181;m
                                            </span>
                                            <span>Mean intensity:</span>
                                            <span>
                                                {cell.meanIntensity !== null ? cell.meanIntensity.toFixed(2) : ''}
                                            </span>
                                        </div>
                                    </ReactTooltip>
                                ))}
                            </div>
                        )}
                    </div>
                </CardBody>
            </Card>
        );
    }
}

WsiDisplay.propTypes = {
    title: PropTypes.string,
    /**
     * The component's title.
     */
    wsi: PropTypes.object,
    cells: PropTypes.array,
    loaded: PropTypes.bool,
    selectedCell: PropTypes.object,
    onClick: PropTypes.func,
    pixelDiameterInMicrometer: PropTypes.number,
};

WsiDisplay.defaultProps = {
    title: 'Img',
    loaded: false,
};

export default WsiDisplay;
