// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.date-range .react-datepicker-wrapper {
  display: flex;
  flex: 1 1;
  max-width: 160px;
}

.date-range .react-datepicker__input-container {
  width: 100%;
}

@media (max-width: 575px) {
  .date-range .react-datepicker-wrapper {
    max-width: 100%;
  }
}
`, "",{"version":3,"sources":["webpack://./src/assets/range-date-picker.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,SAAO;EACP,gBAAgB;AAClB;;AAEA;EACE,WAAW;AACb;;AAEA;EACE;IACE,eAAe;EACjB;AACF","sourcesContent":[".date-range .react-datepicker-wrapper {\n  display: flex;\n  flex: 1;\n  max-width: 160px;\n}\n\n.date-range .react-datepicker__input-container {\n  width: 100%;\n}\n\n@media (max-width: 575px) {\n  .date-range .react-datepicker-wrapper {\n    max-width: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
